<template>
  <div class="pay-success">
    <div class="header flex flex-column cen-center m-bottom-md">
      <icon-font class="icon-font m-bottom-md" type="iconcorrect-empty"></icon-font>
      <div class="font-heading-2 m-bottom-sm">您的订单已支付成功！</div>
      <div class="font-reverse-color-sub text-center">
        <span class="mobile-block">“激活音乐授权”后可以下载高清音频、授权书</span>
      </div>
    </div>

    <div class="container-xs">
      <pay-result-music-content
        v-if="order_type === $val.orderType.music"
        :scope="curInfo"
        :status="$val.PayResultStatus.success"
        @success="onSuccess"
      >
        <template v-slot:operation>
          <router-link class="go-auth" :to="`/user?menukey=${$val.UserMenu.buy}`">去授权</router-link>
        </template>
      </pay-result-music-content>
      <pay-result-package-content v-else :scope="curInfo">
        <template v-slot:operation>
          <router-link class="go-auth" :to="`/user?menukey=${$val.UserMenu.buy}`">去授权</router-link>
        </template>
      </pay-result-package-content>
    </div>
  </div>
</template>

<script>
  import PayResultMusicContent from './result/PayResultMusicContent.vue'
  import PayResultPackageContent from './result/PayResultPackageContent.vue'

  export default {
    data() {
      return {
        curInfo: {},
        order_id: this.$route.params.order_id,
        order_type: this.$route.query.order_type
      }
    },

    watch: {
      order_id: {
        handler(newVal) {
          this.$nextTick(() => {
            newVal && this.getOrderDetail({order_id: newVal});
          })
        },
        immediate: true
      }
    },

    computed: {
      orderInfoFunc() {
        let order_type = this.order_type;

        return order_type === this.$val.orderType.music ? this.$axios.OrderInfo : this.$axios.Package_orderInfo;
      }
    },

    components: {
      PayResultMusicContent, PayResultPackageContent
    },
    methods: {

      getOrderDetail(params) {
        this.$store.commit('changeContentLoading', true);

        this.orderInfoFunc(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.curInfo = data.data || {};
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      },

      onSuccess() {
        this.getOrderDetail({order_id: this.order_id})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pay-success {
    .header {
      height: 232px;
      color: #fff;
      padding: 40px 0;
      background-color: $success-color;

      .icon-font {
        /deep/ {
          svg {
            width: 72px;
            height: 72px;
          }
        }
      }
    }
  }
</style>
